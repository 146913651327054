<template>
  <section class="bg-white py-8 mb:py-12 xl:py-24">
    <div class="container">
      <h2
        v-if="hasPromotions"
        class="text-center mb-6 md:mb-10 xl:mb-16 font-medium text-2xl lg:text-3xl xl:text-4xl"
        aria-label="{{ promotions.promotionsTitle }} {{ promotions.promotionsSubtitle }}"
        tabindex="0"
      >
        {{ promotions.promotionsTitle }} {{ promotions.promotionsSubtitle }}
      </h2>
      <div class="grid grid-cols-1 md:grid-cols-3 grid-spacing">
        <PromotionCard
          v-for="(promo, i) in promotions.promos"
          :key="i"
          :promo="promo"
          :index="i"
          class="flex-1"
        ></PromotionCard>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    promotions: {
      type: Object,
      default() {},
      required: true
    }
  },
  computed: {
    hasPromotions() {
      return Object.keys(this.promotions.promos).length > 0
    }
  }
}
</script>
